/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {
    .huge {
        @apply text-150 m:text-300 leading-0_85 uppercase font-condensed font-black;
    }
    .h1 {
        @apply text-60 m:text-140 leading-0_85 uppercase font-condensed font-black;
    }
    .h2 {
        @apply text-50 m:text-100 leading-0_85 uppercase font-condensed font-black;
    }
    .h3 {
        @apply text-50 m:text-80 leading-0_85 uppercase font-condensed font-black;
    }
    .h4 {
        @apply text-40 m:text-50 leading-0_85 uppercase font-condensed font-black;
    }
    .ingress, .wysiwyg h2 {
        @apply text-24 m:text-30 leading-1_25;
    }
    .body-large {
        @apply text-20 m:text-24 leading-1_25;
    }
    .body {
        @apply text-18 m:text-20 leading-1_25;
    }
    .body-then-large {
        @apply text-18 m:text-24 leading-1_25;
    }
    .small {
        @apply text-15 m:text-16 leading-1_25;
    }

    .button {
        @apply inline-flex items-center px-18 m:px-20 rounded-5px;
        height: 54px;
        &:not(button) {
            @apply pb-2;
        }
        @screen m {
            height: 58px;
        }
        svg {
            margin-left: 2px;
            margin-right: -4px;
            margin-bottom: -2px;
        }
    }

    .radiobutton {
        @apply flex items-center relative;
        input {
            @apply placed-image opacity-0 z-1;
        }
        span {
            @apply relative z-0 block w-22px h-22px border-current border-2 rounded-20px;
            &:after {
                @apply absolute rounded-10px bg-current top-50 left-50;
                content: '';
                width: 10px;
                height: 10px;
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }
        }
        input:checked ~ span:after {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }

        input:focus ~ span {
            html.outline & {
                outline: 5px auto Highlight;
                outline: 5px auto -webkit-focus-ring-color;
            }
        }

        input:not(:checked):hover ~ span:after {
            transform: translate(-50%, -50%) scale(0.75);
            opacity: 0.75;
        }
    }

    .error-message {
        display: none;
        span {
            @apply inline-block bg-error-background text-error-foreground small rounded-5px p-12 m:p-15 mt-10;
        }
        input[aria-invalid] ~ &, select[aria-invalid] ~ & {
            display: block;
        }
    }

    .tag-circle {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 1px solid currentColor;
        margin-right: 6px;
        @screen m {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            margin-bottom: -1px;
        }
        &--filled {
            background: currentColor;
        }
    }

    .background {
        &:before, &:after {
            display: table;
            content: '';
        }
    }

    .has-background {
        & + & {
            @apply -mt-50 mp:-mt-100;
        }
        &:last-child {
            @apply mb-0;
        }
    }
}
