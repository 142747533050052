.page-footer {
    position: relative;
    z-index: 3;
    &__logo {
        width: 110px;
        height: 28px;
        @screen m {
            width: 125px;
            height: 32px;
        }
        &-partners {
            @screen m {
                width: 150px;
                height: 65px;
            }
        }
    }
}
