.wysiwyg {
    p, ul, ol, li, a, h2, h3, i, em, b, strong {
        &:empty {
            display: none;
        }
    }

    p, ul, ol {
        margin-bottom: (22.5/18) * 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        padding-left: 1em;
        list-style: disc;
        li {
            display: list-item;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            align-items: center;
            &:before {
                content: counter(ol-counter) ". ";
                font-weight: 600;
                font-size: 0.9em;
                margin-top: 0.1em;
                margin-right: 0.4em;
            }
        }
    }

    li {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    b, strong {
        font-weight: 600;
    }

    i, em {
        font-style: italic;
    }

    h2 {
        font-weight: 600;
        margin-top: 1.5em;
        margin-bottom: 0.25em;
        &:first-child {
            margin-top: 0;
        }
    }

    &--apply {
        b, strong {
            font-weight: 500;
        }
        li {
            margin-bottom: 1.25em;
        }
        ol li {
            align-items: flex-start;
            &:before {
                font-weight: 500;
            }
        }
    }
}
