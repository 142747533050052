.calulator {
    &__table {
        width: 100%;
        thead th {
            text-align: left;
            font-weight: 600;
            padding-bottom: 25px;
            border-bottom: 1px solid;
        }

        tbody {
            tr:last-child {
                font-weight: 600;
            }
            td {
                padding: 16px 0;
                border-bottom: 1px solid;
            }
            td:last-child {
                text-align: right;
            }
        }
    }

    input[type=range] {
        width: 100%;
        margin: 10.5px 0;
        background: transparent;
        appearance: none;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        background: #000000;
        border: 0;
        width: 100%;
        height: 1px;
        cursor: pointer;
    }
    input[type=range]::-webkit-slider-thumb {
        margin-top: -10.5px;
        width: 22px;
        height: 22px;
        background: currentColor;
        border: 0;
        border-radius: 12px;
        cursor: pointer;
        appearance: none;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #000000;
    }
    input[type=range]::-moz-range-track {
        background: #000000;
        border: 0;
        width: 100%;
        height: 1px;
        cursor: pointer;
    }

    .needs-light-track input[type=range]::-webkit-slider-runnable-track {
        background: #fff;
    }
    .needs-light-track input[type=range]:focus::-webkit-slider-runnable-track {
        background: #fff;
    }
    .needs-light-track input[type=range]::-moz-range-track {
        background: #fff;
    }

    input[type=range]::-moz-range-thumb {
        width: 22px;
        height: 22px;
        background: currentColor;
        border: 0;
        border-radius: 12px;
        cursor: pointer;
    }
    input[type=range]::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 10.5px 0;
        color: transparent;
        width: 100%;
        height: 1px;
        cursor: pointer;
    }
    input[type=range]::-ms-fill-lower {
        background: #000000;
        border: 0;
    }
    .needs-light-track input[type=range]::-ms-fill-lower {
        background: #fff;
    }
    input[type=range]::-ms-fill-upper {
        background: #000000;
        border: 0;
    }
    .needs-light-track input[type=range]::-ms-fill-upper {
        background: #fff;
    }
    input[type=range]::-ms-thumb {
        width: 22px;
        height: 22px;
        background: currentColor;
        border: 0;
        border-radius: 12px;
        cursor: pointer;
        margin-top: 0;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: #000000;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: #000000;
    }

    .needs-light-track input[type=range]:focus::-ms-fill-lower {
        background: #fff;
    }
    .needs-light-track input[type=range]:focus::-ms-fill-upper {
        background: #fff;
    }
}
