.textfield {
    input,
    textarea {
        & + label > span {
            transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        }
    }
    input {
        height: 73px;
        @screen m {
            height: 90px;
        }
    }
    textarea {
        height: 219px;
        resize: none;
        @screen m {
            height: 240px;
        }
    }
    label > span {
        transform-origin: left top;
    }
    input:not(:placeholder-shown),
    textarea:not(:placeholder-shown),
    input:focus,
    textarea:focus {
        & + label > span {
            opacity: 1;
            transform: translate(2px, -10px) scale(0.77777778, 0.77777778);
            @screen m {
                transform: translate(4px, -10px) scale(0.6666667, 0.6666667);
            }
        }
    }

    input:not(:placeholder-shown),
    input:focus {
        border-width: 3px;
        padding-top: 44px;
        @screen m {
            padding-top: 48px;
        }
    }
    textarea:not(:placeholder-shown),
    textarea:focus {
        border-width: 3px;
        padding-top: 34px;
        @screen m {
            padding-top: 40px;
        }
    }

    input[aria-invalid] {
        border-color: var(--error-background);
    }

    &--small {
        input {
            height: 54px;
            @screen m {
                height: 58px;
            }
        }
    }
}
