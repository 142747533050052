.video-loop {
    [data-image] {
        opacity: 0;
        html.no-js & {
            opacity: 1;
        }
    }

    [data-video] {
        html.no-js & {
            display: none;
        }
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.5s ease-out;
    }

    &.js-is-playing [data-video] {
        opacity: 1;
    }
}
