.ticker {
    @keyframes ticker-left {
        from {
            transform: translate3d(0, 0, 0);
        }

        to {
            transform: translate3d(-100%, 0, 0);
        }
    }
    @keyframes ticker-right {
        from {
            transform: translate3d(0, 0, 0);
        }

        to {
            transform: translate3d(100%, 0, 0);
        }
    }
    > span {
        will-change: transform;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: var(--duration, 2s);
        animation-name: var(--animation, 'ticker-left');
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
    }
}
