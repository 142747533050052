.filter-widget {
    position: relative;
    margin-right: 25px;

    &__toggle {
        @apply text-black bg-transparent border-1;
        transition: all 0.3s ease-out;
        .has-dark-background & {
            @apply text-white;
        }

        &:hover,
        &:focus,
        &[aria-expanded="true"] {
            @apply text-white bg-black;
            .has-dark-background & {
                @apply bg-white text-black;
            }
        }
        &[aria-expanded="true"] svg {
            transform: translateY(0) rotate(-180deg) !important;
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        background: white;
        max-height: 300px;
        min-width: 100%;
        margin-top: 10px;
        z-index: 1;
        flex-direction: column;
        overflow-y: scroll;
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        overscroll-behavior: contain;
        @apply text-black;

        [aria-expanded="true"] + & {
            display: flex;
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            white-space: nowrap;
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }

    &__apply {
        .no-js &,
        .js-error & {
            display: block;
            border-bottom: 1px solid currentColor;
            transition: border-bottom-color 0.3s $easeOutQuad;

            html.using-mouse &:hover,
            html.outline &:focus {
                border-bottom-color: transparent;
            }
        }
    }

    &__clear:not([hidden]) {
        display: block;
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.3s $easeOutQuad;

        html.using-mouse &:hover,
        html.outline &:focus {
            border-bottom-color: transparent;
        }
    }
}
