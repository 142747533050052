.badge {
    position: fixed;
    width: 80px;
    right: 25px;
    bottom: 25px;
    z-index: 2;
    pointer-events: none;
    user-select: none;
    display: none;

    &--mobile {
        display: block;
    }
    @screen m {
        width: 100px;
        right: 50px;
        bottom: 50px;
        display: none;
        &--desktop {
            display: block;
        }
    }
}
