.page-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    visibility: hidden;
    opacity: 0;

    &:target {
        visibility: visible;
        opacity: 1;
    }

    &__items {
        transform-origin: bottom left;
        transition: transform 0.3s $easeOutCubic;
        html.using-mouse &:hover,
        html.outline &:focus {
            transform: skew(10deg);
        }
    }

    &__plus {
        svg {
            margin-top: 4px;
            @screen m {
                margin-top: 5px;
                width: 36px;
                height: 36px;
            }
        }
    }

    &__submenu {
        [aria-expanded="true"] + & {
            display: block;
        }
    }
}
