/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {
    @font-face {
        font-family: 'Barlow';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/barlow-regular.woff2') format('woff2'), url('../fonts/barlow-regular.woff') format('woff');
    }
    @font-face {
        font-family: 'Barlow';
        font-weight: 400;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/barlow-italic.woff2') format('woff2'), url('../fonts/barlow-italic.woff') format('woff');
    }
    @font-face {
        font-family: 'Barlow';
        font-weight: 500;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/barlow-500.woff2') format('woff2'), url('../fonts/barlow-500.woff') format('woff');
    }
    @font-face {
        font-family: 'Barlow';
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/barlow-600.woff2') format('woff2'), url('../fonts/barlow-600.woff') format('woff');
    }
    @font-face {
        font-family: 'Barlow';
        font-weight: 600;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/barlow-600-italic.woff2') format('woff2'), url('../fonts/barlow-600-italic.woff') format('woff');
    }

    @font-face {
        font-family: 'BarlowCondensed';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/barlow-condensed-regular.woff2') format('woff2'), url('../fonts/barlow-condensed-regular.woff') format('woff');
    }
    @font-face {
        font-family: 'BarlowCondensed';
        font-weight: 900;
        font-style: normal;
        font-display: swap;
        src: url('../fonts/barlow-condensed-900.woff2') format('woff2'), url('../fonts/barlow-condensed-900.woff') format('woff');
    }
    @font-face {
        font-family: 'BarlowCondensed';
        font-weight: 900;
        font-style: italic;
        font-display: swap;
        src: url('../fonts/barlow-condensed-900-italic.woff2') format('woff2'), url('../fonts/barlow-condensed-900-italic.woff') format('woff');
    }
}
