/* ===============================
=            Choices            =
=============================== */
.choices {
    position: relative;
    overflow: hidden;
    font-size: 18px;
}
.choices:focus {
    outline: none;
}
.choices:last-child {
    margin-bottom: 0;
}
.choices.is-open {
    overflow: visible;
}

.choices [hidden] {
    display: none !important;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
    cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
    position: relative;
    display: inline-block;
    margin-left: 12px;
    padding-left: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2807 0.719971L0.720703 11.28M0.720703 0.719971L11.2807 11.28' stroke='%23ffffff' stroke-width='1.2'/%3E%3C/svg%3E");
    background-size: 12px;
    width: 12px;
    top: 1px;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
    opacity: 1;
}

.choices__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: transparent;
    padding: 15px 15px 0;
    border: 2px solid currentColor;
    font-size: 18px;
    overflow: hidden;
    @screen m {
        font-size: 24px;
    }
}

.choices__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.choices__list:empty + .choices__input {
    margin-bottom: 14px;
}

.choices__list--multiple {
    display: inline;
}
.choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 5px;
    padding: 16px 20px;
    font-size: 20px;
    margin: auto 7px 14px;
    background-color: #000;
    color: #fff;
    word-break: break-all;
    box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
    padding-right: 12px;
}

.choices__list--multiple .choices__item.is-highlighted {
    //background-color: #00a5bb;
    //border: 1px solid #008fa1;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 2px solid #000;
    top: 100%;
    margin-top: -2px;
    overflow: hidden;
    word-break: break-all;
    will-change: visibility;
}
.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
    visibility: visible;
}
.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
    //border-color: #b7b7b7;
}
.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
}
.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
    position: relative;
    padding: 10px;
    font-size: 18px;
}
@media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
        padding-right: 100px;
    }
    .choices__list--dropdown .choices__item--selectable::after, .choices__list[aria-expanded] .choices__item--selectable::after {
        content: attr(data-select-text);
        font-size: 14px;
        font-weight: 500;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
    background-color: rgba(255, 181, 16, 0.15);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
    opacity: 0.5;
}

.choices__item {
    cursor: default;
}

.choices__item--selectable {
    cursor: pointer;
}

.choices__item--disabled {
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}

.choices__heading {
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #f7f7f7;
    color: gray;
}

.choices__button {
    text-indent: -9999px;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.choices__input {
    appearance: none;
    display: inline-block;
    vertical-align: top;
    background-color: transparent;
    font-size: 18px;
    border: 0;
    max-width: 100%;
    height: 57px;
    padding-left: 7px;
    @screen m {
        font-size: 24px;
    }
    &:focus {
        outline: 0;
    }
    &::placeholder {
        opacity: 0.5;
        color: inherit;
    }
}

.choices__input::-webkit-search-decoration, .choices__input::-webkit-search-cancel-button, .choices__input::-webkit-search-results-button, .choices__input::-webkit-search-results-decoration {
    display: none;
}
.choices__input::-ms-clear, .choices__input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/* =====  End of Choices  ====== */
