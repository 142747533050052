.data-calulator {
    &__table {
        width: 100%;
        thead th {
            font-weight: normal;
            text-align: left;
            padding-bottom: 10px;
            border-bottom: 1px solid;
            &:last-child {
                @include until(m) {
                    display: none;
                }
            }
        }

        tbody {
            td {
                padding: 16px 0;
                border-bottom: 1px solid;
            }
        }

        @include until(m) {
            tbody td {
                display: block;
                &:first-child {
                    padding-top: 10px;
                    padding-bottom: 2px;
                    border-bottom: 0;
                }
                &:last-child {
                    padding-top: 2px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    input[type=range] {
        width: 100%;
        appearance: none;
        height: 22px;
        background: #000;
        outline: none;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        background: #000000;
        border: 0;
        width: 100%;
        height: 22px;
        cursor: pointer;
    }
    input[type=range]::-webkit-slider-thumb {
        appearance: none;
        width: 63px;
        height: 22px;
        background: #b39cdb;
        cursor: pointer;
        border: 0 !important;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #000000;
    }
    input[type=range]::-moz-range-track {
        background: #000000;
        border: 0;
        width: 100%;
        height: 22px;
        cursor: pointer;
    }
    input[type=range]::-moz-range-thumb {
        width: 63px;
        height: 22px;
        background: #b39cdb;
        cursor: pointer;
        border: 0 !important;
    }
    input[type=range]::-ms-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
        width: 100%;
        height: 22px;
        cursor: pointer;
    }
    input[type=range]::-ms-fill-lower {
        background: #000000;
        border: 0;
    }
    input[type=range]::-ms-fill-upper {
        background: #000000;
        border: 0;
    }
    input[type=range]::-ms-thumb {
        width: 63px;
        height: 22px;
        background: #b39cdb;
        cursor: pointer;
        border: 0 !important;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: #000000;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: #000000;
    }
}
