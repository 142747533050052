.checkbox {
    @apply text-18 m:text-24 leading-1_25;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    input {
        opacity: 0;
        position: absolute;
        top: 2px;
        left: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        @screen m {
            top: 5px;
        }
    }

    svg {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        order: -1;
        cursor: pointer;
        flex: 0 0 auto;
        .hover {
            display: none;
        }
        .checked {
            display: none;
        }
    }

    &:hover svg, &:focus-within svg {
        .default {
            display: none;
        }
        .hover {
            display: block;
        }
    }

    input:checked + svg {
        // @apply bg-current;
        .checked {
            display: block;
        }
    }

    span {
        user-select: none;
        cursor: pointer;
    }
}
