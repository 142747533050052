.accordion-button {
    .vert {
        transform-origin: 50% 50%;
        will-change: transform;
        transition: transform 0.3s $easeOutCubic;
    }
    &[aria-expanded=true] .vert {
        transform: scaleY(0);
    }
}
