/* ==============================
    Basic/generic global styles
 ============================== */

html {
    &.js-menu-open {
        @apply bg-blue;
    }
    &.js-at-bottom:not(.js-menu-open) {
        @apply bg-footer;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 104px;

    @screen m {
        padding-top: 158px;
    }

    &.has-hero {
        padding-top: 0;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.45s ease-in-out;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.links, .wysiwyg {
    a {
        border-bottom: 1px solid currentColor;
        transition: border-bottom-color 0.3s $easeOutQuad;

        html.using-mouse &:hover,
        html.outline &:focus {
            border-bottom-color: transparent;
        }
    }
}

.links--inverted {
    a {
        border-bottom: 1px solid transparent;
        transition: border-bottom-color 0.3s $easeOutQuad;

        html.using-mouse &:hover,
        html.outline &:focus {
            border-bottom-color: currentColor;
        }
    }
}

a, button {
    .arrow-left, .arrow-right, .arrow-up-right, .arrow-down {
        transition: all 0.25s ease-out;
    }
    html.using-mouse &:hover:not(:disabled),
    html.outline &:focus:not(:disabled) {
        .arrow-left {
            transform: translateX(-4px);
        }
        .arrow-right {
            transform: translateX(4px);
        }
        .arrow-up-right {
            transform: translate(4px, -4px);
        }
        .arrow-down {
            transform: translateY(4px);
        }
    }
}

.image-zoom {
    img, video {
        will-change: opacity, transform;
        transition: transform 0.5s $easeOutQuad;
        &.lazyloaded {
            transition: transform 0.5s $easeOutQuad, opacity 0.45s ease-in-out;
        }
    }
    html.using-mouse &:hover,
    html.outline &:focus {
        img, video {
            transform: scale(1.015);
        }
    }
    &__image {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        max-width: none;
    }
}

.ratio {
    padding-bottom: calc(100% / (var(--ratio-p)));
    @media (orientation: landscape) {
        padding-bottom: calc(100% / (var(--ratio-ls)));
    }
}

[hidden] {
    display: none;
}
