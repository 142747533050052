@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.submit {
    position: relative;
    &:disabled {
        pointer-events: none;
    }
    &__arrow {
        :disabled & {
            opacity: 0;
        }
    }
    &__spinner {
        position: absolute;
        right: 18px;
        opacity: 0;
        transition: opacity 0.25s ease-out;
        animation: spinner 1s linear infinite;
        :disabled & {
            opacity: 1;
        }
    }
}
