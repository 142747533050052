.article-slider {
    &__button {
        transition: opacity 0.45s ease-in-out;
        &:disabled {
            opacity: 0.25;
            cursor: default;
        }
        @screen mp {
            svg {
                width: 39px;
                height: 40px;
                path {
                    stroke-width: 5px;
                }
            }
        }
    }
}
