.play-button {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: #000;
        border-radius: 100px;
        z-index: -1;
        transition: transform 0.35s $easeInOutQuad;
        a:hover & {
            transform: scale(1.075);
        }
    }
    @include from(mp) {
        .video--half & {
            width: 150px;
            height: 150px;
            font-size: 20px;
        }
        .video--full &,
        .video--bleed & {
            width: 180px;
            height: 180px;
            font-size: 24px;
        }
    }
}
