/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .round {
            border-radius: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .fullheight {
            height: 100vh;
            @supports (--custom:property) {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        .clear {
            &:before, &:after {
                content: "";
                display: table;
            }
            &:after {
                clear: both;
            }
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        .object-cover {
            object-fit: cover;
            width: 100%;
            height: 100%;
            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: none;
                max-height: none;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .object-contain {
            object-fit: contain;
            width: 100%;
            height: 100%;
            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        .placed-wrap {
            position: relative;
            height: 0;
        }

        .placed-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .scrollable {
            @include scrollable();
        }

        .scrollable-x {
            @include scrollableX();
        }

        .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

    }

}
