.page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &.headroom {
        position: fixed;
        will-change: transform, background, color;
        transition: transform 350ms ease-in-out, background 350ms ease-in-out, color 350ms ease-in-out;
        transform: translateY(0%);
        color: #000;
        &.is-partners {
            color: #fff;
        }
    }
    &.headroom--pinned {
        background: rgba(255, 255, 255, 0.975);
        transform: translateY(0%);
        &.is-partners {
            background: rgba(5, 5, 5, 0.925);
        }
    }
    &.headroom--top {
        background: transparent !important;
    }
    &.headroom--unpinned {
        transform: translateY(-100%);
    }
    &.headroom--frozen {
        background: rgba(255, 255, 255, 0.975);
        &.is-partners {
            background: rgba(5, 5, 5, 0.925);
        }
    }
    &.headroom--scrolled {
        transition: none !important;
    }
    &.headroom--initial {
        transition: transform 1.5s $easeOutExpo;
    }

    html:not(.js-menu-open) &:not(.headroom--not-top) {
        @apply text-header;
    }

    html.js-menu-open &.is-partners {
        @apply text-black;
    }

    &__logo {
        width: 110px;
        height: 28px;
        @screen m {
            width: 175px;
            height: 46px;
        }

        &-partners {
            @screen m {
                width: 150px;
                height: 65px;
            }
        }
    }

    &__hamburger {
        margin-top: 2px;
        margin-left: 6px;
        @screen mp {
            margin-top: 3px;
            margin-left: 9px;
        }
        i {
            display: block;
            width: 12px;
            height: 1px;
            margin-bottom: 3px;
            background: currentColor;
            @screen mp {
                width: 14px;
                margin-bottom: 4px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__items {
        display: none;
        @media (min-width: 850px) {
            display: flex;
        }
    }

    &__submenu {
        flex-direction: column;
        position: absolute;
        background: white;
        right: 0;
        top: 100%;
        margin-top: 5px;
        padding: 20px 25px 25px 20px;
        z-index: 1;
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        [aria-expanded="true"] + & {
            display: flex;
        }
    }
}
