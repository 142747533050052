.select {
    @apply relative inline-flex items-center pl-18 m:pl-20 pr-40 m:pr-44 py-14 m:py-16 rounded-5px border-2 border-current bg-transparent appearance-none;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 17.76v-12m0 12 5.28-5.28M12 17.76l-5.28-5.28' stroke='currentColor'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: calc(100% - 12px) calc(50% + 1px);

    transition: background-position-y 0.25s ease-out;

    &.use-light-arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 17.76v-12m0 12 5.28-5.28M12 17.76l-5.28-5.28' stroke='%23FFFFFF'/%3E%3C/svg%3E%0A");
    }

    &[aria-invalid] {
        border-color: var(--error-background);
    }

    @screen m {
        background-position: calc(100% - 16px) calc(50% + 2px);
    }

    html.using-mouse &:hover,
    html.outline &:focus {
        background-position-y: calc(50% + 5px);
    }
}
